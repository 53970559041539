import * as React from "react";
import { RoutePage } from "../Components/RouteDetails/RoutePage";
import { Footer } from "../Components/Footer";
function route() {
  return (
    <>
      <RoutePage />
      <Footer />
    </>
  );
}

export default route;
