import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Image } from "../Image";
function Landmarks() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulLandmark {
        nodes {
          id
          landmark
          image {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  `);

  const {
    allContentfulLandmark: { nodes },
  } = data;
  return (
    <>
      <div className="landmarks">
        <ul>
          {nodes.map((n) => {
            return (
              <li key={n.id}>
                <h3>{n.landmark}</h3>
                <Image
                  image={n.image}
                  classId="landmark--images"
                  alt={n.landmark}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export { Landmarks };
