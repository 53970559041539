import React, { useState } from "react";
import { LandmarkDetails } from "./LandmarkDetails";
import { RouteHeader } from "./RouteHeader";
import { BackToTop } from "../BackTotop";
import { Theme } from "../Theme";
function RoutePage() {
  const [Inter, setInter] = useState({ state: true });
  return (
    <>
      <main className="route--page--main" id="route">
        <RouteHeader setInter={setInter} />
        <LandmarkDetails />
        <div className="theme--and--top">
          {!Inter.state ? <BackToTop id="route" /> : ""}
          <Theme />
        </div>
      </main>
    </>
  );
}

export { RoutePage };
