import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { HeroText } from "../homepage/HeroText";
import { HeroImage } from "../homepage/HeroImage";
function RouteHero() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulRouteHeader {
        nodes {
          title
          body
          headerImage {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  `);

  const {
    allContentfulRouteHeader: {
      nodes: [{ title, body, headerImage }],
    },
  } = data;
  return (
    <>
      <div className="hero--container">
        <HeroText
          title={title}
          body={body}
          goto=""
          value="Home Page"
          id="header--link"
        />
        <HeroImage
          image={headerImage}
          classId="hero-image"
          alt="route illustration"
        />
        <HeroImage
          image={headerImage}
          classId="hero-image2"
          alt="route illustration"
        />
      </div>
    </>
  );
}

export { RouteHero };
