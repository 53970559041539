import React from "react";
import { Landmarks } from "./Landmarks";
function LandmarkDetails() {
  return (
    <>
      <div className="landmark--wrapper">
        <div className="landmark--top">
          <h2>Landmark Details</h2>
          <p>These Details help you to reach my clinic</p>
        </div>
        <Landmarks />
      </div>
    </>
  );
}

export { LandmarkDetails };
